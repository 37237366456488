import './Dash.scss';
import {
  // useAreas,
  useEnts,
  // useHistory,
  useFind,
  useLog,
} from './hooks/useHomeAssistantCore.js';

// import {
  // useBinarySensorsWithDeviceClass,
  // useEntsWithDeviceClass,
  // useSensorsWithDeviceClass,
// } from './hooks/useHomeAssistant.js';

import Calendar from './Calendar.js';
import Clock from './Clock.js';
import { 
  useEffect,
  useState,
  React,
   } from 'react';
import { ThemeProvider, SectionLayout } from './homekit-react-components/src/';

import { CameraCard } from './homekit-react-components/src/';


import AgeCard from './AgeCard.js';
// import spinner from './images/spinner-animated.svg';

import {
  AllDoors,
  AllGarageDoors,
  AllLights,
  AllMotions,
  AllWindows,
  // LightsCluster,
  // Area,
  Entity,
  // LEDBar,
  DishwasherCard,
  LaundryCard,
  WaterHeaterCard,
  UPSCard,
  temperatureToColor,
} from './Entities.jsx';

import {
  // exteriorAreaIds,
  // bedroomAreaIds,
  ignoreMotionIds,
  energyConsumers,
  interestingTemperatures,
  primaryCameras,
  interestingWaterQuantities,
} from './houseConfig';

import {
  PowerDiagram,
  EnergyDiagram,
} from './EnergyFlow';

import {
  EnergyHistory,
  TemperatureHistory,
  WaterHistory,
  PowerRatesGraph,
  HistoryGraph,
 } from './HistoryGraphs';

// function EntitiesGroupdByArea({entity_ids,filter=null}) {
//   const areas = useAreas();
//   const grouped = {};
//   for (let area_id in areas.area_to_id) {
//     grouped[area_id] = entity_ids
//       .filter(a => areas.area_to_id[area_id].includes(a));
//   }
//   return (
//     Object.keys(grouped)
//       .map(g => <Area key={g} area_id={g} entity_ids={grouped[g]} filter={filter} />)
//   );
// }

function Log() {
  const logs = useLog();
  return (
    <pre>{logs ? logs.join('\n') : 'x'}</pre>
  );
}

function TempCrossover() {
  const [outdoor, indoor]  = useEnts([
    'sensor.gw1000b_v1_7_6_outdoor_temperature',
    'sensor.gw1000b_v1_7_6_temperature_4',
    ]);
  const unit = '°F';
  const min = 50;
  const max = 100;
  if (!outdoor || !outdoor.state) {
    return null;
  }
  if (!indoor || !indoor.state) {
    return null;
  }
  let outhot = true;
  if (parseFloat(indoor.state) > parseFloat(outdoor.state)) {
    outhot = false;
  }

  return (
    <div>
    <div>
      <span className="c1" style={{color:temperatureToColor(outdoor.state,min,max)}}>{outdoor.state}<span>{unit}</span></span>
      Outdoors
    </div>
    <div>
      <span className="c2" style={{color:temperatureToColor(indoor.state,min,max)}}>{indoor.state}<span>{unit}</span></span>
      Indoors
    </div>
    {outhot?<div></div>:<div className="differentoutside">Hotter INSIDE than out.</div>}
    </div>
  );
}

function ScryptedCameras() {
  const cameras = {
    // 'Front Door': 'http://192.168.3.45:11080/endpoint/@scrypted/webhook/public/57/e33d32ad22a89a3b/takePicture',
    // 'Driveway': 'http://192.168.3.45:11080/endpoint/@scrypted/webhook/public/46/a51a5d04cfd7bb9d/takePicture',
    //'Front Door': 'https://scrypted.3754.xyz/endpoint/@scrypted/webhook/public/57/e33d32ad22a89a3b/takePicture',
    // 'Driveway': 'https://scrypted.3754.xyz/endpoint/@scrypted/webhook/public/46/a51a5d04cfd7bb9d/takePicture',
    'Driveway': 'https://cam1.3754.xyz/snap.jpeg',
    'Front Door': 'https://cam2.3754.xyz/snap.jpeg',
  };
  return Object.keys(cameras).map(a => <ReloadingCameraCard key={a} name={a} url={cameras[a]} />);
}

function ReloadingCameraCard({name,url}) {
  const interval = 5;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const iv = setInterval(() => {
      setCounter(counter + 1);
    }, 1000 * interval);

    return () => {
      clearInterval(iv);
    };
  },[interval, counter]);


  return (
    <CameraCard
      skipLoading={true}
      name={name}
      previewImageSrc={url + '?_ts=' + Date.now()}
    />
  );
}


function Dash() {
  const days = 1;

  return (
    <ThemeProvider>
    <div className="Dash">
      <div style={{position:'absolute',top:0,left:0,width: 700,height:500}}>
        <Clock />
        <Calendar days={4} />
      </div>
      <div style={{position:'absolute',top:0,right:0,width: 400,height:500}}>
      <SectionLayout>
        <ScryptedCameras />
        <AgeCard/>
      </SectionLayout>
      </div>
      <div style={{height:640,width:'99vw'}} />
      <SectionLayout>
        {Object.keys(interestingTemperatures).map(k => <TemperatureHistory days={days} key={'t'+k} entity_ids={interestingTemperatures[k]} label={k} />)}
      </SectionLayout>

      <SectionLayout>
        {Object.keys(energyConsumers).map(k => <EnergyHistory days={days} key={k} entity_ids={energyConsumers[k]} label={k} />)}        
        {/* OMG SO MUCH DATA 
        \{Object.keys(interestingWaterQuantities).map(k => <WaterHistory days={days} key={k} entity_ids={interestingWaterQuantities[k]} label={k} />)} */}
        <HistoryGraph days={1} entity_ids={{'sensor.powerwall_charge':{color:'green'}}} min={0} max={100} label="Powerwall Charge" />
        <PowerRatesGraph />
        <TempCrossover />
      </SectionLayout>
<SectionLayout>
<DishwasherCard />
<LaundryCard tag={'left'} />
<LaundryCard tag={'right'} />
<WaterHeaterCard />
</SectionLayout>
      <SectionLayout>
        <AllLights interior={false} />
        <AllLights interior={true} />
        <AllGarageDoors /> 
        <AllDoors interior={false} />
        <AllWindows />
        <AllMotions exclude={ignoreMotionIds} />
        <AllDoors interior={true} />
        <Entity entity_id={'climate.parents_room_2'} />
        <Entity entity_id={'climate.kitchen_2'} />
        <Entity entity_id={'binary_sensor.bakah_charger'} />
        <Entity entity_id={'binary_sensor.mom_y_charger'} />
        {/* <Entity entity_id={'sensor.dishwasher_last_run'} /> */}
        {/* <Entity entity_id={'sensor.left_laundry_last_run'} /> */}
        {/* <Entity entity_id={'sensor.right_laundry_last_run'} /> */}
        {/* <Entity entity_id={'binary_sensor.esp_poe1_compressor'} /> */}
        <Entity entity_id={'sensor.water_meter_municipal_water_flow_rate'} />
        <Entity entity_id={'sensor.esphome_web_a8c7c4_dispensed_hot_water'} />
        <Entity entity_id={'sensor.powerwall_charge'} />
      </SectionLayout>
      <SectionLayout>
        <Entity entity_id={'sensor.powerwall_load_now'} />
        <Entity entity_id={'sensor.powerwall_site_now'} />
        <Entity entity_id={'sensor.powerwall_solar_now'} />
        <Entity entity_id={'sensor.powerwall_site_import'} />
        <Entity entity_id={'sensor.powerwall_battery_now'} />
      </SectionLayout>
      <SectionLayout>
        <PowerDiagram />
        <EnergyDiagram />
      </SectionLayout>
    </div>
    </ThemeProvider>
  );
}

export default Dash;
