import moment from 'moment-timezone';
import {
  React,
} from 'react';

function daysUntil(date) {
  var birthday = moment(date);
  
  // uncomment this line to simulate it is your birthday and comment the next one to test it.
  // var today = moment('2017-03-25');
  var today = moment().format('YYYY-MM-DD');
  
  // calculate age of the person
  var age = moment(today).diff(birthday, 'years');
  moment(age).format('YYYY-MM-DD');
   
  var nextBirthday = moment(birthday).add(age, 'years');
  moment(nextBirthday).format('YYYY-MM-DD');
  
  /* added one more year in case the birthday has already passed
  to calculate date till next one. */
  if (nextBirthday.isSame(today)) {
    return 0;
  }
  nextBirthday = moment(birthday).add(age + 1, 'years');
  return nextBirthday.diff(today, 'days');
}

const colors = {
  'A': '#20bde0',
  'Z': '#e159b2',
  'K': '#a46cff',
  'J': '#8943c2',
  'N': '#86adc2',
  'Y': 'brown',
  'X': 'red',
  'S': 'yellow',
};

function AgeCards() {
  // console.log('AgeCards rerun')
  const people = {
    Z: '2012-09-24',
    A: '2014-08-28',
    K: '2017-06-21',
    J: '1979-01-25',
    N: '1976-09-14',
    Y: '2023-04-17', // guessed
    X: '1900-12-25',
    S: '2020-08-14',
  };
  
  const pp = Object.keys(people).sort((k,j) => daysUntil(people[k]) > daysUntil(people[j]) ? 1 : -1);

  const s = (p) => { return {
    textShadow:'0px 0px 4px white,0px 0px 2px white',
    fontSize: 30,
    color:colors[p],
  };};

  return (
    <div className='agecard'>
      <table>
        <thead>
          <tr>
            {pp.map(p => <td style={s(p)} key={p}>{p}</td>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            {pp.map(p => <td style={s(p)} key={p}><div className='numeric'>{daysUntil(people[p])}</div></td>)}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AgeCards;